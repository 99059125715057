<template>
<div class="about-page w-full h-screen" id="element">
  <popupSuccess
    :want-to-make-offer="activeSuccess"
    @oga="activeSuccess = false"
    :titre="successTitle"
    :message="successMessage"
    :bouton="successBouton"
    :redirect="successRedirect"
    redirect-path="portefeuille"
  />
  <confirm-resilier :want-to-make-offer="activeResil" :police="donnes.policyNumber" @oga="backResil"/>
  <confirmSuspend :want-to-make-offer="activeSuspend" @oga="backSuspend"/>
  <confirmActivate :want-to-make-offer="activeActivate" v-if="activeActivate" @oga="backReactivate"/>

  <div class="flex items-center cursor-pointer" @click="retour" v-if="iscommercial">
      <svg-icon
        name="back"
        class="h-16 mr-4 ico"
        original
      />
    <div class="titre">Détails</div>
  </div>

  <div class="flex items-center cursor-pointer" v-if="!iscommercial">
    <svg-icon
      name="back"
      class="h-16 mr-4 ico"
      original
    />
    <div class="titre">Détails</div>
  </div>

  <div class="baniere md:flex items-center p-6 mt-6" :class="{'desactivate': donnes.isSuspend}">
    <div class="md:w-2/3 first">
      <div class="flex items-center">
        <div class="enonce">Police n° : </div>
        <div class="reponse ml-2 mr-4"> {{ donnes.policyNumber }} </div>
        <div class="active-bout flex items-center justify-center pl-4 pr-4" v-if="!donnes.isSuspend">
          <div>Active</div>
        </div>

        <div class="inactive-bout flex items-center justify-center pl-4 pr-4" v-if="donnes.isSuspend">
          <div>Inactive</div>
        </div>
      </div>

      <div class="flex items-center mt-2">
        <div class="enonce">Aussurance
          <span v-if="donnes.product.categorie === 'AUTO' || donnes.product.categorie === 'Automobile'"> automobile</span>
          <span v-if="donnes.product.categorie === 'MOTO' || donnes.product.categorie === 'Moto'"> moto</span>
          <span v-if="donnes.product.categorie === 'MRH' || donnes.product.categorie === 'Habitation'"> habitation</span>
          <span v-if="donnes.product.categorie === 'VOYAGE' || donnes.product.categorie === 'Voyage'"> voyage</span>
          : </div>
        <div class="reponse ml-2 mr-4">
          <span v-if="donnes.product.categorie === 'AUTO' || donnes.product.categorie === 'Automobile'">{{details.carBrand}} - {{details.carModel}}</span>
          <span v-if="donnes.product.categorie === 'MOTO' || donnes.product.categorie === 'Moto'">{{details.motorBrand}} - {{details.motoRegistration}}</span>
          <span v-if="donnes.product.categorie === 'MRH' || donnes.product.categorie === 'Habitation'">{{ details.habitationType.toUpperCase() }}</span>
          <span v-if="donnes.product.categorie === 'VOYAGE' || donnes.product.categorie === 'Voyage'">{{details.ownerCountry.name}} - {{details.country.name}}</span>
        </div>
      </div>

      <div class="flex items-center mt-2">
        <div class="enonce">Montant de la prime : </div>
        <div class="reponse ml-2 mr-4"> {{ details.financeData.primeTTC.toLocaleString() }} Fr </div>
      </div>

<!--      <div class="flex items-center mt-2">-->
<!--        <div class="enonce">Numero de contrat : </div>-->
<!--        <div class="reponse ml-2 mr-4"> 287478278 </div>-->
<!--      </div>-->

    </div>

    <div class="md:flex justify-end md:w-1/3 second">
      <div class="button flex pl-5" @click="viewDetail">
        <div class="mr-2">
          Détails  <span v-if="donnes.product.categorie === 'AUTO' || donnes.product.categorie === 'Automobile'"> du véhicule</span>
          <span v-if="donnes.product.categorie === 'MOTO' || donnes.product.categorie === 'Moto'">de la moto</span>
          <span v-if="donnes.product.categorie === 'MRH' || donnes.product.categorie === 'Habitation'">de l'habitation</span>
          <span v-if="donnes.product.categorie === 'VOYAGE' || donnes.product.categorie === 'Voyage'">du voyage</span>
        </div>
        <svg-icon
          name="chevron-right"
          class="h-6"
          original
        />
      </div>
    </div>
  </div>

  <div class="md:flex justify-center mt-4">
<!--    <buton-->
<!--      :charge="charge"-->
<!--      label="Changer de véhicule"-->
<!--      icon="car"-->
<!--      radius="14px"-->
<!--      weight="500"-->
<!--      height="58px"-->
<!--      background="#FFF"-->
<!--      color="black"-->
<!--      class="md:w-1/3 mt-2"-->
<!--    />-->
    <buton
      v-if="expireDay <= 3 && expireDay >= -30"
      :charge="charge"
      label="Prolonger mon contrat"
      icon="prolonger"
      radius="14px"
      weight="500"
      height="58px"
      background="#FFF"
      color="black"
      class="md:w-1/3 mt-2"
      @info="viewDetail"
    />

    <buton
      v-if="!donnes.isSuspend"
      :charge="charge"
      label="Suspendre ma couverture"
      icon="pause"
      radius="14px"
      weight="500"
      height="58px"
      background="#FFF"
      color="black"
      class="md:w-1/3 md:ml-6 mt-2"
      @info="activeSuspend = true"
    />

    <buton
      v-if="donnes.isSuspend"
      :charge="charge"
      label="Réactiver ma couverture"
      icon="play"
      radius="14px"
      weight="500"
      height="58px"
      background="#FFF"
      color="black"
      class="md:w-1/3 md:ml-6 mt-2"
      @info="activeActivate = true"
    />
<!--    <buton-->
<!--      :charge="charge"-->
<!--      label="Résilier le contrat"-->
<!--      icon="closer"-->
<!--      radius="14px"-->
<!--      weight="500"-->
<!--      height="58px"-->
<!--      background="#FDE9E9"-->
<!--      color="#ED2121"-->
<!--      class="md:w-1/3 md:ml-6 mt-2"-->
<!--      @info="activeResil = true"-->
<!--    />-->
  </div>

  <div class="mt-5">
    <div class="md:flex">
      <div class="flex md:w-1/2 items-center cedeao pl-5 pr-5 md:mr-5 md:mb-0 mb-4" v-if="donnes.product.categorie === 'AUTO' || donnes.product.categorie === 'Automobile'">
        <svg-icon
          name="cedeao"
          class="md:h-12 h-6 mr-4"
          original
        />
        <div class="w-full">
          En cas de voyage à l’intérieur de la CEDEAO, prière passer chercher en agence votre attestation CEDEAO
        </div>
      </div>

      <div class="flex md:w-1/2 alerte pl-5 pr-5 items-center">
        <svg-icon
          name="mdi_alert"
          class="h-6 mr-4"
          original
        />
        <div class="w-full ecrit">
          En cas de sinistre veuillez vous rendre dans le <a @click="sinistre">menu sinistre</a> pour effectuer votre déclaration. Vous pourrez ainsi suivre le traitement de votre dossier sans vous déplacer ;
        </div>
      </div>
    </div>

    <div class="mt-5 md:flex items-center" v-if="donnes.product.categorie === 'AUTO' || donnes.product.categorie === 'Automobile'">
      <div class="md:w-4/5 ecrit md:text-left text-center">
        Vous pouvez également nous écrire par WhatsApp pour vous faire délivrer l’attestation, les frais de livraison étant à votre charge ;
      </div>
      <div class="md:1/5 md:text-left text-center">
        <div class="button whatsapp" @click="whatsapp">
          <svg-icon
            name="whatsapp"
            class="h-4 mr-2"
            original
          />
          <div>Contactez-nous</div>
        </div>
      </div>
    </div>

  </div>

  <div class="md:flex justify-center mt-12">
    <div class="md:w-1/3 md:mr-4" v-if="donnes.certificate !== null">
      <optionBloc icons="sinistre" :lien="donnes.certificate" sous-title="Attestation numérique" class="w-full"/>
    </div>

    <div class="md:w-1/3 md:mr-4">
      <optionBloc icons="sinistre" :lien="condition" sous-title="Conditions générales" class="w-full"/>
    </div>

    <div class="md:w-1/3">
      <optionBloc icons="sinistre" v-if="donnes.subscriptionStates[0]" :lien="donnes.subscriptionStates[0].receipt"  sous-title="Reçu de paiement" class="w-full"/>
    </div>

    <div class="md:w-1/3 md:ml-4" v-if="donnes.certificate === null">
      <optionBloc icons="sinistre" :lien="donnes.other_certs.special" sous-title="Conditions particulières" class="w-full"/>
    </div>
  </div>

  <div class="md:flex mt-4">
    <div class="md:w-1/3 md:mr-4" v-if="donnes.certificate !== null">
      <optionBloc icons="sinistre" :lien="donnes.other_certs.special" sous-title="Conditions particulières" class="w-full"/>
    </div>
    <div class="w-1/3 mr-4">
<!--      <optionBloc icons="sinistre" sous-title="Conditions particulières" class="w-full"/>-->
    </div>
    <div class="w-1/3">
<!--      <optionBloc icons="sinistre" sous-title="Conditions particulières" class="w-full"/>-->
    </div>
  </div>
</div>
</template>

<script>
import optionBloc from '@/components/cedro/souscription/optionBloc'
import buton from '@/components/helper/add/button'
import confirmSuspend from '@/components/popup/confirmSuspend'
import confirmActivate from '@/components/popup/confirmReactivation'
import popupSuccess from '@/components/popup/popupSuccess'
import confirmResilier from '@/components/popup/confirmResilier'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'index',
  components: {
    optionBloc,
    buton,
    confirmSuspend,
    popupSuccess,
    confirmResilier,
    confirmActivate
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      details: null,
      condition: '',
      iscommercial: true,
      activeSuspend: false,
      activeSuccess: false,
      successTitle: 'Effectué !',
      successMessage: '',
      successBouton: 'Ok',
      successPath: 'souscription',
      successRedirect: 'vos souscriptions',
      activeResil: false,
      charge: false,
      activeActivate: false,
      expireDay: null
    }
  },

  created () {
    // console.log('detttt')
    // console.log(this.donnes)
    this.details = JSON.parse(this.donnes.meta)

    const date1 = new Date(this.donnes.expireAt)
    const date2 = new Date()
    const dif = date2.getTime() - date1.getTime()
    this.expireDay = dif / (1000 * 3600 * 24)
    // console.log(this.expireDay)
  },

  mounted () {
    this.iscommercial = this.$store.getters.trackCommercial

    this.smooth()
    if (this.donnes.product.categorie === 'AUTO' || this.donnes.product.categorie === 'Automobile') {
      this.condition = 'https://firebasestorage.googleapis.com/v0/b/aabenin-6f488.appspot.com/o/cg%2Fcg_auto.pdf?alt=media&token=63be54fe-9005-4730-a152-793be70b2c68'
    }
    if (this.donnes.product.categorie === 'MRH' || this.donnes.product.categorie === 'Habitation') {
      this.condition = 'https://firebasestorage.googleapis.com/v0/b/aabenin-6f488.appspot.com/o/cg%2Fcg_mrh.pdf?alt=media&token=93b794e9-6211-4edc-a8b0-25492eb461e8'
    }
  },

  methods: {
    expireVerif () {

    },

    backReactivate (a) {
      if (a) {
        this.charge = true
        http.post(apiroutes.baseURL + apiroutes.activateContrat, {
          id: this.donnes.id,
          date: new Date().getTime()
        })
          .then(response => {
            console.log('suspension effective')
            console.log(response)
            this.successMessage = 'Votre police d’assurance n° :' + this.donnes.policyNumber +
              ' a été reactiver avec succès. Vous pouvez la retrouver dans '
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            console.log('erreur')
            console.log(error)
            if (error.data.statusCode === 401) {
              this.$store.dispatch('logout', null)
              this.$router.push('/login')
            }
          })
      }
      this.activeActivate = false
    },

    backSuspend (a) {
      if (a) {
        this.charge = true
        http.post(apiroutes.baseURL + apiroutes.suspendContrat, {
          id: this.donnes.id,
          date: new Date().getTime()
        })
          .then(response => {
            console.log('suspension effective')
            console.log(response)
            this.successMessage = 'Votre police d’assurance n° :' + this.donnes.policyNumber +
              ' est suspendu, pour continuer a bénéficier de votre couverture, réactive la police dans '
            this.activeSuccess = true
          })
          .catch(error => {
            this.charge = false
            console.log('erreur')
            console.log(error)
            if (error.data.statusCode === 401) {
              this.$store.dispatch('logout', null)
              this.$router.push('/login')
            }
          })
      }
      this.activeSuspend = false
    },

    backResil (a) {
      if (a) {
        this.charge = true
        this.successRedirect = null
        this.charge = false
        this.successBouton = 'Mon portefeuille'
        this.successMessage = 'Votre contrat d’assurance n° :' + this.donnes.policyNumber +
          ' a été résilier avec succès. Le montant restant de votre prime sera reversé dans votre portefeuille client'
        this.activeSuccess = true
        //
        // http.post(apiroutes.baseURL + apiroutes.suspendContrat, {
        //   id: this.donnes.id,
        //   date: new Date().getTime()
        // })
        //   .then(response => {
        //     console.log('suspension effective')
        //     console.log(response)
        //
        //   })
        //   .catch(error => {
        //     this.charge = false
        //     console.log('erreur')
        //     console.log(error)
        //     if (error.data.statusCode === 401) {
        //       this.$store.dispatch('logout', null)
        //       this.$router.push('/login')
        //     }
        //   })
      }
      this.activeResil = false
    },

    whatsapp () {
      window.open('https://api.whatsapp.com/send?phone=22963632828', '_blank')
    },

    viewDetail () {
      this.donnes.meta = this.details
      this.$store.dispatch('saveSouscription', this.donnes)
      this.$router.push('/details')
    },

    retour () {
      this.$emit('oga', false)
    },

    sinistre () {
      this.$router.push('/sinistre')
    },

    smooth () {
      const element = document.getElementById('element')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.baniere{
  width: 100%;
  border-radius: 20px;
  height: 159px;
  background: #0269AD;
  color: #FFFFFF;
}
.cedeao{
  height: 97px;
  background: #FFC56D;
  border-radius: 20px;
  font-family: $font-default;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #000000;
}
.alerte{
  height: 97px;
  border-radius: 20px;
  background: #E8E8E8;
}
.ecrit{
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  color: #404040;
}
a{
  text-decoration: underline;
}
.active-bout{
  height: 24.31px;
  background: #FFFFFF;
  border-radius: 12.1558px;
  font-weight: 500;
  font-size: 13.0909px;
  line-height: 17px;
  color: #00BF53;
}

.inactive-bout{
  height: 24.31px;
  background: #e9a1a1;
  border-radius: 12.1558px;
  font-weight: 500;
  font-size: 13.0909px;
  line-height: 17px;
  color: #ffffff;
}

.enonce{
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.reponse{
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.about-page{
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
  .titre{
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    font-family: $rubik
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .button{
    height: 71px;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.04em;
    color: #000000;
    font-family: $font-default;
    border: none;
  }
  .svg-icon{
    fill: white;
  }
.button:hover{
  background-color: #ecf0f5;
}
.whatsapp{
  height: 34px;
  background: #29C85F;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #FFFFFF;
}
.whatsapp:hover{
  background-color: #3eb15a;
}
.desactivate{
  background-color: #979797;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .ico{
    height: 3rem;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .ico{
    height: 3rem;
  }
  .baniere{
    display: block;
    padding: 30px !important;
    height: auto;
  }
  .first, .second{
    width: 100%;
  }
  .second{
    display: block;
    margin-top: 20px;
  }
  .button{
    height: 55px;
  }
  .cedeao, .alerte{
    height: 115px;
    font-size: 12px;
    padding: 10px;
  }
}

@media screen and (max-width: 700px){
  .about-page{
    width: 100%;
  }
  .ico{
    height: 2.4rem;
  }
  .titre{
    font-size: 25px;
  }
  .baniere{
    height: auto;
    border-radius: 20px;
    padding: 20px !important;
    margin-top: 20px !important;
    .enonce{
      font-size: 14.8px;
    }
    .reponse{
      font-size: 18.2px;
    }
  }
  .button{
    height: 50px;
    font-size: 14px;
    margin-top: 20px !important;
    width: 70%;
  }
  .cedeao, .alerte{
    height: auto;
    font-size: 12px;
    padding: 10px;
  }

}

@media screen and (max-width: 325px){
  .titre{
    font-size: 22px;
  }
  .active-bout{
    display: none;
  }
  .baniere{
    height: auto;
    border-radius: 20px;
    padding: 20px !important;
    margin-top: 20px !important;
    .enonce{
      font-size: 12.8px;
    }
    .reponse{
      font-size: 14.2px;
    }
  }
  .button{
    height: 50px;
    font-size: 14px;
    margin-top: 20px !important;
    width: 100%;
  }
}
</style>
