<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="titre text-center pt-4 mb-5">Confirmation</div>
          <div class="sous-titre mt-5">
            Réactivez votre police d’assurance maintenant pour continuer à bénéfier de votre couverture ?
          </div>

          <div class="md:flex justify-center mt-6">
            <div class="button annuler md:w-1/2 mr-4 cursor-pointer" @click="fermer">Annuler</div>
            <div class="button continuer md:w-1/2" @click="yes">Oui, réactiver</div>
          </div>

        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    yes () {
      this.$emit('oga', true)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre {
      font-weight: bold;
      font-size: 28px;
      line-height: 160%;
      text-align: center;
      color: #000000;
    }
    .sous-titre {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: #000000;
    }
    .button{
      background-color: #00BF53;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #00BF53;
      box-sizing: border-box;
      border-radius: 10px;
    }

    .annuler{
      font-weight: normal;
      font-size: 20px;
      line-height: 153%;
      text-align: center;
      color: #191919;
      background: #EDEDEE;
      border: 1px solid #EDEDEE;
      border-radius: 10px;
    }
    .button:hover{
      border: 1px solid #cfcff1;
    }
    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 700px){
      .success-container{
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        padding: 50px 30px !important;
        text-align: center;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 17px;
      }

      .button{
        width: 100%;
        height: 50px;
        font-size: 17px;
      }
    }

</style>
