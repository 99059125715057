<template>
<div class="w-full h-full rounded-lg all flex p-5">
  <viewPdf :want-to-make-offer="activPdf" v-if="activPdf" @oga="retourPdf"/>
  <div class=" w-full cursor-pointer" @click="openPdf">
    <div class="">
      <svg-icon
        :name="icons"
        class="h-12"
        original
      />
    </div>
    <div class="sous mt-2">{{sousTitle}}</div>

    <div class="mt-5 button" >
      <div>Télécharger le document</div>
      <svg-icon
        name="download"
        class="h-3 ml-2"
        original
      />
    </div>
  </div>
</div>
</template>

<script>
import viewPdf from '@/components/popup/viewPdf'

export default {
  name: 'index',
  components: {
    viewPdf
  },
  props: {
    nom: {
      type: String,
      default: ''
    },
    sousTitle: {
      type: String,
      default: ''
    },
    icons: {
      type: String,
      default: 'smilet'
    },
    lien: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      activPdf: false
    }
  },

  methods: {
    retourPdf (answer) {
      this.activPdf = answer
    },
    openPdf () {
      window.open(this.lien, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .nom{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
.sous{
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
}

.all{
 width: 100%;
  height: 247px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 20px 20px 0px 0px;
  font-family: $font-default;
}
.all:hover{
  margin-top: -10px;
  background: linear-gradient(180deg, #f1e8e8 0%, rgba(255, 255, 255, 0) 100%)
}
.button{
  font-weight: normal;
  font-size: 12.5659px;
  line-height: 160%;
  color: #737D84;
  border: 0.897561px solid #737D84;
  box-sizing: border-box;
  border-radius: 7.18049px;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .nom{
    font-size: 20px;
  }
  .sous{
    font-size: 14px;
  }
  .button{
    font-size: 10.5659px;
  }
  .all{
    height: 190px;
  }
}

@media screen and (max-width: 700px){
  .all{
    height: 190px;
  }
  .nom{
    font-size: 20px;
  }
  .sous{
    font-size: 14px;
  }
}
</style>
